<template>
  <div
    class="
      w-full
      flex flex-col
      items-center
      justify-center
      py-4
      h-screen
      bg-white
      dark:bg-jevi-dark
    "
  >
    <img src="@/assets/img/error/403.svg" alt="No autorizado" />
    <h1 class="text-2xl font-semibold text-primary-800 dark:text-white">
      {{ $i18n.t("access_denied") }}
    </h1>
    <p class="text-primary-700 dark:text-white">
      {{ $i18n.t("access_denied_t2") }}.
    </p>
    <t-button class="mt-4" @click="redirectHome()"
      >{{ $i18n.t("return") }} {{ $i18n.t("to_home") }}</t-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    redirectHome() {
      window.localStorage.removeItem("vuex")
      window.location.href = "/"
    }
  }
}
</script>
